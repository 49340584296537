import { Box, Flex, Image } from '@chakra-ui/react';
import { memo, useEffect } from 'react';
import { useSections } from 'app/hooks/sections/useSections';
import './styles.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { Section } from 'app/models';

export const BannerListLabel = memo(() => {
  const { getAllLabels, allLabels } = useSections();
  const history = useHistory();
  const { pathname } = useLocation();

  const isContributorsPage = pathname.includes('/contributors');
  const isMultipacksPage = pathname.includes('/multipacks');

  useEffect(() => {
    getAllLabels();
  }, [getAllLabels]);

  const buildUrl = (
    label: Section,
    isMultipacksPage: boolean,
    isContributorsPage: boolean,
  ) => {
    if (isMultipacksPage) {
      return {
        pathname: '/multipacks',
        state: {
          type: 'showSections',
          value: label?._id,
          label: label?.name,
        },
      };
    }
    return `labels/${label?._id}${isContributorsPage ? '?tab=3' : '?tab=1'}`;
  };

  return (
    <Box
      mb={{ base: '28px', md: '20px', xl: '20px' }}
      className="banner-container"
    >
      <Flex className="list-labels" mt={{ base: '12px', md: 'unset' }}>
        {allLabels?.map((label: Section) => (
          <Box key={label?._id} width={{ base: '16%', md: '12%' }}>
            <Image
              className="label-item"
              onClick={() =>
                history.push(
                  buildUrl(label, isMultipacksPage, isContributorsPage),
                )
              }
              src={label?.squareImageUrl}
            />
          </Box>
        ))}
      </Flex>
    </Box>
  );
});
