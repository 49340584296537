import { useDisclosure } from '@chakra-ui/react';
import { useGlobalLoadingSlice } from 'app/components/Loading/slice';
import { WebsocketContext } from 'app/contexts/WebsocketContext';
import { selectAuth } from 'app/pages/Login/slice/selectors';
import { useTrackSlice } from 'app/pages/Tracks/slice';
import { selectSliceTracks } from 'app/pages/Tracks/slice/selectors';
import { useWishlistsSlice } from 'app/pages/Wishlist/slice';
import { selectSliceWishlists } from 'app/pages/Wishlist/slice/selectors';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useWishlists = () => {
  const socket = useContext(WebsocketContext);
  const { userDetail } = useSelector(selectAuth);

  const {
    myWishlists,
    isCheckOpenModelAddTrackToCart,
    isAddedTracksOrRelease,
    addedTrackIdOrReleaseId,
  } = useSelector(selectSliceWishlists);
  const {
    isOpen: isShowModalDelete,
    onOpen: onOpenModalDelete,
    onClose: onCloseModalDelete,
  } = useDisclosure();

  const {
    isOpen: isShowModalMessageRemove,
    onOpen: onOpenModalMessageRemove,
    onClose: onCloseModalMessageRemove,
  } = useDisclosure();

  const {
    isOpen: isShowModalPaypalCheckout,
    onOpen: onOpenModalPaypalCheckout,
    onClose: onCloseModalPaypalCheckout,
  } = useDisclosure();

  const {
    isOpen: isShowModalBuyTrackByStar,
    onOpen: onOpenModalBuyTrackByStar,
    onClose: onCloseModalBuyTrackByStar,
  } = useDisclosure();

  const {
    isOpen: isShowModalTrackBelongRelease,
    onOpen: onOpenModalTrackBelongRelease,
    onClose: onCloseModalTrackBelongRelease,
  } = useDisclosure();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isLoadingBuyTrack } = useSelector(selectSliceTracks);

  const dispatch = useDispatch();
  const { actions } = useWishlistsSlice();
  const actionsTracks = useTrackSlice();
  const { actions: globalLoadingActions } = useGlobalLoadingSlice();
  const onGetMyWishlists = useCallback(() => {
    dispatch(actions.getWishlistRequest());
  }, [actions, dispatch]);

  useEffect(() => {
    dispatch(actions.setIsCheckOpenModelAddTrackToCart(isOpen));
  }, [actions, dispatch, isOpen]);

  const handleAddTrackToWishlist = useCallback(
    trackId => {
      dispatch(actions.addTrackToMyWishlistRequest(trackId));
      onClose();
    },
    [actions, dispatch, onClose],
  );

  const handleAddReleaseToWishlist = useCallback(
    releaseId => {
      dispatch(actions.addReleaseToMyWishlistRequest(releaseId));
      onClose();
    },
    [actions, dispatch, onClose],
  );

  const handleAfterRemoveTrackToWishlist = useCallback(
    trackIds => {
      dispatch(actions.removeTrackToMyWishlistRequest(trackIds));
      onClose();
      onCloseModalDelete();
      onCloseModalMessageRemove();
    },
    [actions, dispatch, onClose, onCloseModalDelete, onCloseModalMessageRemove],
  );

  const handleRemoveTrackToWishlist = useCallback(
    trackIds => {
      handleAfterRemoveTrackToWishlist(trackIds);
    },
    [handleAfterRemoveTrackToWishlist],
  );

  // const handleRemoveAllTrackToWishlist = useCallback(() => {
  //   const listIds: any = myWishlists.map(i => i?.track?.id);
  //   handleAfterRemoveTrackToWishlist(listIds);
  // }, [handleAfterRemoveTrackToWishlist, myWishlists]);

  const handleRemoveReleaseToMyWishlist = useCallback(
    trackIds => {
      dispatch(actions.removeReleaseToMyWishlistRequest(trackIds));
      onClose();
      onCloseModalDelete();
      onCloseModalMessageRemove();
    },
    [actions, dispatch, onClose, onCloseModalDelete, onCloseModalMessageRemove],
  );

  const onCheckoutSuccess = async data => {
    dispatch(globalLoadingActions.showLoading());
    socket.emit('onCheckoutCartSuccess', {
      data,
      clientId: socket.id,
      userId: userDetail._id,
    });
  };

  const buyTrackByStar = async trackId => {
    dispatch(actionsTracks.actions.buyTrackByStarRequest(trackId));
  };

  return {
    onGetMyWishlists,
    myWishlists,
    handleAddTrackToWishlist,
    handleRemoveTrackToWishlist,
    // handleRemoveAllTrackToWishlist,
    isOpen,
    onOpen,
    onClose,
    isShowModalDelete,
    onOpenModalDelete,
    onCloseModalDelete,
    isShowModalMessageRemove,
    onOpenModalMessageRemove,
    onCloseModalMessageRemove,
    handleAddReleaseToWishlist,
    isShowModalPaypalCheckout,
    onOpenModalPaypalCheckout,
    onCloseModalPaypalCheckout,
    handleRemoveReleaseToMyWishlist,
    onCheckoutSuccess,
    userDetail,
    isShowModalBuyTrackByStar,
    onOpenModalBuyTrackByStar,
    onCloseModalBuyTrackByStar,
    buyTrackByStar,
    isLoadingBuyTrack,
    isCheckOpenModelAddTrackToCart,
    isShowModalTrackBelongRelease,
    onOpenModalTrackBelongRelease,
    onCloseModalTrackBelongRelease,
    isAddedTracksOrRelease,
    addedTrackIdOrReleaseId,
  };
};
