import { Box } from '@chakra-ui/react';
import { useCallback, useEffect } from 'react';
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from 'react-icons/hi';
import ReactPaginate from 'react-paginate';
import Empty from '../Empty';
import TrackItem from '../TrackItem';
import TrackList from '../TrackList';
import { renderLoadingTracks, renderTrackListItem } from '../TrackUtils/track';
export function TracksAndPagination({
  setFilter,
  tracks = [],
  filter,
  currentPage,
  itemsRef,
  isShowAllTracks,
  totalPage,
  isLoading,
  isShowExpandedAll = true,
  handleShowAllTrack,
}: any) {
  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, tracks.length);
  }, [itemsRef, tracks.length]);

  const handlePageClick = useCallback(
    value => {
      setFilter(current => ({
        ...current,
        page: +value.selected + 1,
      }));
    },
    [setFilter],
  );

  const renderTracks = () => {
    if (isLoading) {
      return renderLoadingTracks(10);
    }

    if (!tracks.length) return <Empty />;

    const TrackContent = () =>
      (tracks || []).map((track, index) => (
        <TrackItem
          key={track.id}
          index={index + 1}
          refEye={el => (itemsRef.current[index] = el)}
          track={track}
          sort={filter?.sort}
          isShowAllTracks={isShowAllTracks}
        />
      ));

    return <Box>{renderTrackListItem(TrackContent())}</Box>;
  };

  return (
    <Box>
      <TrackList
        children={renderTracks()}
        tracks={tracks}
        isShowExpandedAll={isShowExpandedAll}
        handleShowAllTrack={handleShowAllTrack}
      />
      <ReactPaginate
        className="pagination-container"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={totalPage}
        previousLabel={<HiOutlineArrowSmLeft size="22" color="##4A5568" />}
        nextLabel={<HiOutlineArrowSmRight size="22" color="##4A5568" />}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        breakClassName="page-item"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={currentPage - 1}
      />
    </Box>
  );
}
