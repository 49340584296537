import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useMediaScreen } from 'app/hooks/mediaScreen/useMediaScreen';
import { useBreakpointValue } from '@chakra-ui/react';
import DropDown from '../Common/Dropdowns';
import Search from './Search';

export default function SearchAndSort({
  placeHolder,
  handleChange,
  listSort,
  searchValue,
  valueSort,
  handleChangeFilter,
  mbSearch,
  handleFilterCheckbox,
  filter,
  isShowCheckboxs = false,
}: {
  placeHolder: string;
  handleChange: any;
  listSort: any;
  searchValue: string;
  valueSort: any;
  handleChangeFilter: any;
  mbSearch?: any;
  handleFilterCheckbox?: any;
  filter?: any;
  isShowCheckboxs?: boolean;
}) {
  const bgSubFilter = useColorModeValue('#fff', '#1A1F2C');
  const { isLargerThan600 } = useMediaScreen();
  const widthDropDown = useBreakpointValue({
    base: '100%',
    md: '132px',
    lg: '170px',
  });

  return (
    <Flex
      flexDirection={isLargerThan600 ? 'row' : 'column-reverse'}
      gridGap="12px"
      justifyContent={{ base: 'space-between', xl: 'unset' }}
      bg={bgSubFilter}
      flexWrap={{ base: 'wrap', md: 'unset' }}
      w="100%"
    >
      <Flex gridGap="12px" alignItems="center">
        <Box w={{ base: '100%', md: 'unset' }}>
          <Text
            fontSize="12px"
            fontWeight="600"
            color={useColorModeValue('#616161', '#fff')}
          >
            Sort by:
          </Text>
          <DropDown
            width={widthDropDown}
            filters={listSort}
            handleChangeDropDown={handleChangeFilter}
            name="sort"
            value={valueSort}
          />
        </Box>
      </Flex>
      <Search
        mb={mbSearch}
        searchValue={searchValue}
        placeHolder={placeHolder}
        handleChange={handleChange}
        filter={filter}
        handleFilterCheckbox={handleFilterCheckbox}
        isShowCheckboxs={isShowCheckboxs}
      />
    </Flex>
  );
}
