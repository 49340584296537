import { socket } from 'app/contexts/WebsocketContext';
import { toastError, toastSuccess } from 'app/helpers/toast';
import { useTrackSlice } from 'app/pages/Tracks/slice';
import { useHistory, useLocation } from 'react-router-dom';

import { useGlobalLoadingSlice } from 'app/components/Loading/slice';
import { AUDIO, MP3_FILE, MP4_FILE } from 'app/constants';
import { SORT_TYPE } from 'app/constants/enum';
import { getLocalStorage, removeLocalStorage } from 'app/helpers/local-storage';
import { useSubscriptionsSlice } from 'app/pages/Subscriptions/slice';
import { selectSliceTracks } from 'app/pages/Tracks/slice/selectors';
import { actionsTrackDetail } from 'app/pages/TracksDetail/slice';
import { actionsWishlists, useWishlistsSlice } from 'app/pages/Wishlist/slice';
import axios from 'axios';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useSocket = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { actions } = useWishlistsSlice();
  const { actions: globalLoadingActions } = useGlobalLoadingSlice();
  const { actions: actionsSubscriptions } = useSubscriptionsSlice();
  const { actions: actionsTrack } = useTrackSlice();
  const { searchValue } = useSelector(selectSliceTracks);

  const isMyLibrary = useMemo(() => {
    return pathname.includes('/my-library');
  }, [pathname]);

  const options = useMemo(() => {
    return {
      success: async () => {
        const trackId = getLocalStorage('trackId');
        dispatch(
          actionsTrack.updateNumberDownloadTrackRequest({
            trackId,
          }),
        );
        toastSuccess('Success! Files saved to your Dropbox.');
        removeLocalStorage('trackId');
        dispatch(
          actionsTrack.updateIsSavingDropboxTrack({
            isSavingDropboxTrack: false,
          }),
        );
      },
      process: function () {},
      cancel: function () {
        removeLocalStorage('trackId');
        dispatch(
          actionsTrack.updateIsSavingDropboxTrack({
            isSavingDropboxTrack: false,
          }),
        );
      },
      error: function (errorMessage) {
        toastError('Some thing went wrong!');
        removeLocalStorage('trackId');
        dispatch(
          actionsTrack.updateIsSavingDropboxTrack({
            isSavingDropboxTrack: false,
          }),
        );
      },
    };
  }, [actionsTrack, dispatch]);

  useEffect(() => {
    socket.on('getPreSignUrlTrack', async data => {
      if (data) {
        if (data?.typeDownload === 'dropbox') {
          const pathName = `${data?.title}.${
            data?.type === AUDIO ? MP3_FILE : MP4_FILE
          }`;
          const button = (window as any)?.Dropbox.save(
            data?.preSignUrl,
            pathName,
            options,
          );
          (window as any)?.document
            ?.getElementById('root')
            ?.appendChild(button);
        } else {
          await axios
            .get(data?.preSignUrl, { responseType: 'blob' })
            .then(async response => {
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute(
                'download',
                `${data?.title} - ${data?.artist}.${
                  data?.type === AUDIO ? MP3_FILE : MP4_FILE
                }`,
              );
              document.body.appendChild(link);
              link.click();
              link.remove();
              if (data?.isUpdateNumberDownload) {
                dispatch(
                  actionsTrack.updateNumberDownloadTrackRequest({
                    trackId: data?.trackId,
                  }),
                );
              } else {
                dispatch(
                  actionsTrack.updateIsDownloadingTrack({
                    isDownloadingTrack: false,
                  }),
                );
              }

              if (data?.isBuyTrackWithSubscription) {
                dispatch(
                  actionsSubscriptions.updateSubscriptionsAfterBuyTrack(),
                );
                dispatch(
                  actionsWishlists.updateTrackandReleaseinWishlistsInReducer([
                    {
                      _id: data?.trackId,
                    },
                  ]),
                );
                dispatch(
                  actionsTrack.buyTrackBySubscriptionSuccess({
                    trackId: data?.trackId,
                  }),
                );
                dispatch(
                  actionsTrackDetail.buyTrackBySubscriptionSuccess({
                    trackId: data?.trackId,
                  }),
                );
                if (data?.isBuyTopTrackWithSubscription) {
                  dispatch(actionsTrack.updateIsDownloadTrackSuccess(true));
                }
                dispatch(actionsTrack.updateTrackBySubscriptionSuccess(false));
                dispatch(actionsTrack.buyTrackIdBySub(null));
                toastSuccess('Download success!');
              }
            })
            .catch(error => {
              console.error('Error downloading track', error);
            });
        }
      }
    });
    return () => {
      socket.off('getPreSignUrlTrack');
    };
  }, [actionsSubscriptions, actionsTrack, dispatch, options]);

  useEffect(() => {
    socket.on('onCheckoutCartSuccess', data => {
      dispatch(actions.checkoutToMyWishlistSuccess());

      // const tracks = (data?.tracks || []).map(t => {
      //   return {
      //     _id: t.trackId,
      //   };
      // });
      // let tracksOfRelease: any = [];
      // (data?.releases || []).forEach(r => {
      //   const track: any = (r.tracks || []).map(t => {
      //     return { _id: t.trackId };
      //   });
      //   tracksOfRelease.push(...track);
      // });
      // const releaseIds = (data?.releases || []).map(r => r.releaseId);

      // const listTracks = tracks.concat(tracksOfRelease);

      // dispatch(actionsTracks.updateTopTracksInReducer(listTracks));
      // dispatch(actionReleases.updateTopReleases(listTracks));
      // dispatch(actionsPlaylists.updateTopPlaylist(listTracks));
      // dispatch(
      //   actionsReleaseDetail.updatePriceReleaseDetailSuccess({
      //     releaseIds,
      //     listTracks,
      //   }),
      // );
      // dispatch(
      //   actionsTrackDetail.updateStateTrackAndTrackOfReleaseAndTrackOfPlaylist(
      //     listTracks,
      //   ),
      // );

      dispatch(globalLoadingActions.hideLoading());
      if (isMyLibrary) {
        const data = {
          // showAudio:
          //   isEmpty(getLocalStorage('showAudio')) ||
          //   getLocalStorage('showAudio'),
          // showVideo:
          //   isEmpty(getLocalStorage('showVideo')) ||
          //   getLocalStorage('showVideo'),
          clean: true,
          dirty: true,
        };
        dispatch(
          actionsTrack.getMyTracksPurchasedRequest({
            sort: SORT_TYPE.CREATED_AT_PURCHASE_DESC,
            search: '',
            data,
          }),
        );
        dispatch(
          actionsTrack.getMyAllTracksPurchasedRequest({
            sort: SORT_TYPE.CREATED_AT_PURCHASE_DESC,
            search: '',
            data,
          }),
        );
      } else history.push('/my-library');
      toastSuccess('Checkout success');
    });
    return () => {
      socket.off('onCheckoutCartSuccess');
    };
  }, [
    actions,
    actionsTrack,
    dispatch,
    globalLoadingActions,
    history,
    isMyLibrary,
    searchValue,
  ]);
};
