import { Box, SimpleGrid } from '@chakra-ui/react';
import { generateArray } from 'app/helpers/functions';
import { useCallback } from 'react';
import { HiOutlineArrowSmLeft, HiOutlineArrowSmRight } from 'react-icons/hi';
import ReactPaginate from 'react-paginate';
import Empty from '../Empty';
import ReleaseItem from '../ReleaseItem';
import SkeletonItem from '../SkeletonItem';
import { useLocation } from 'react-router-dom';

export function ReleasesAndPagination({
  setFilter,
  currentPage,
  totalPage,
  isLoading,
  releases,
}: any) {
  const { pathname } = useLocation();

  const isLabelDetailPage = pathname.includes('/labels/');

  const handlePageClick = useCallback(
    value => {
      setFilter(current => ({
        ...current,
        page: +value.selected + 1,
      }));
    },
    [setFilter],
  );

  const renderUILoadMore = useCallback(
    numberItem => (
      <Box>
        <SimpleGrid
          gridGap="10px"
          rowGap="15px"
          columns={{ base: 1, sm: 2, md: 3, lg: isLabelDetailPage ? 4 : 5 }}
        >
          {generateArray(numberItem).map(item => (
            <SkeletonItem borderRadius="10px" key={item} />
          ))}
        </SimpleGrid>
      </Box>
    ),
    [isLabelDetailPage],
  );

  const renderContent = useCallback(() => {
    if (isLoading) return renderUILoadMore(isLabelDetailPage ? 8 : 10);
    if (!releases.length) return <Empty />;

    return (
      <Box mb="15px">
        <SimpleGrid
          gridGap="10px"
          rowGap="15px"
          columns={{ base: 1, sm: 2, md: 4, lg: isLabelDetailPage ? 4 : 5 }}
        >
          {releases.map((item, idx) => (
            <ReleaseItem key={item._id} release={item} />
          ))}
        </SimpleGrid>
      </Box>
    );
  }, [isLabelDetailPage, isLoading, releases, renderUILoadMore]);

  return (
    <>
      {renderContent()}
      <ReactPaginate
        className="pagination-container"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={totalPage}
        previousLabel={<HiOutlineArrowSmLeft size="22" color="##4A5568" />}
        nextLabel={<HiOutlineArrowSmRight size="22" color="##4A5568" />}
        pageClassName="page-item"
        previousClassName="page-item"
        nextClassName="page-item"
        breakClassName="page-item"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={currentPage - 1}
      />
    </>
  );
}
