import { Box, Flex, Image } from '@chakra-ui/react';
import { getLabelDetail } from 'app/apis/sections/sections';
import { HelmetPage } from 'app/components/HelmetPage';
import classNames from 'classnames';
import { default as queryString } from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AboutInLabel } from './About';
import { ContributorsInLabel } from './Contributors';
import ReleasesInLabel from './Releases';
import { TracksInLabel } from './Tracks';
import styles from './bannerLabel.module.scss';
import { useSections } from 'app/hooks/sections/useSections';
import { Section } from 'app/models';
import Top10Contributors from 'app/components/Top10Contributors';
import Top10Genres from 'app/components/Top10Genres';
import Top10Tags from 'app/components/Top10Tags';
import { useMediaScreen } from 'app/hooks/mediaScreen/useMediaScreen';

export function LabelDetailPage() {
  const { getAllLabels, allLabels } = useSections();

  const [labelDetail, setLabelDetail] = useState<any>(null);
  const { search } = useLocation();
  const [tabIndex, setTabIndex] = useState(1);
  const { isLargerThan522 } = useMediaScreen();

  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const query = queryString.parse(search);
    if (query && query.tab) {
      setTabIndex(+query.tab);
    }
  }, [search]);

  useEffect(() => {
    getAllLabels();
  }, [getAllLabels]);

  const onGetLabelId = useCallback(async () => {
    const res = await getLabelDetail({ labelId: id });
    setLabelDetail(res);
  }, [id]);

  useEffect(() => {
    onGetLabelId();
  }, [onGetLabelId]);

  const listTabs = [
    {
      _id: 1,
      text: 'TRACKS:',
      content: labelDetail?.numberTracks,
    },
    {
      _id: 2,
      text: 'MULTIPACKS:',
      content: labelDetail?.numberReleases,
    },
    {
      _id: 3,
      text: 'CONTRIBUTORS:',
      content: labelDetail?.numberContributors,
    },
    {
      _id: 0,
      text: 'ABOUT',
      content: '',
    },
  ];

  const renderTabContent = useCallback(() => {
    switch (tabIndex) {
      case 0:
        return <AboutInLabel labelDetail={labelDetail} />;

      case 1:
        return <TracksInLabel labelDetail={labelDetail} />;

      case 2:
        return <ReleasesInLabel labelDetail={labelDetail} />;

      case 3:
        return <ContributorsInLabel labelDetail={labelDetail} labelId={id} />;

      default:
        break;
    }
  }, [id, labelDetail, tabIndex]);

  const renderListTab = () => {
    return (
      <Flex direction={isLargerThan522 ? 'row' : 'column'} my="15px">
        {listTabs.map(item => (
          <Flex
            key={item._id}
            onClick={() => {
              setTabIndex(item?._id);
              history.push({
                pathname: `/labels/${labelDetail?._id}`,
                search: `?tab=${item?._id}`,
              });
            }}
            className={classNames(
              styles.informationBanner,
              tabIndex === item._id && styles.informationBannerHover,
            )}
          >
            <Flex className={classNames(styles.text)}>
              {item.text} {item.content}
            </Flex>
          </Flex>
        ))}
      </Flex>
    );
  };

  const listLabels = useMemo(() => {
    return allLabels?.filter(label => label?._id !== id);
  }, [allLabels, id]);

  return (
    <>
      <HelmetPage title="Labels" />
      <Box
        className={styles.containerBannerLabel}
        backgroundColor={labelDetail?.labelBgColor || '#000'}
      >
        <Box className={styles.innerBannerLabel}>
          <Flex
            className={styles.listLabelsBanner}
            pl={{ base: '120px', sm: '132px', md: '13.5%' }}
          >
            {listLabels?.map((label: Section) => (
              <Box
                key={label?._id}
                className={styles.listItem}
                w={{ base: '72px', lg: '100px' }}
                h={{ base: '72px', lg: '100px' }}
              >
                <Image
                  objectFit="cover"
                  className={styles.listItemImage}
                  onClick={() => {
                    history.push(`/labels/${label?._id}?tab=1`);
                    window.location.reload();
                  }}
                  src={label?.squareImageUrl}
                />
              </Box>
            ))}
          </Flex>
        </Box>
        {labelDetail && (
          <Image
            className={styles.bannerImageLabel}
            src={labelDetail.squareImageUrl}
          />
        )}
      </Box>
      <Flex display={{ base: 'block', md: 'flex' }}>
        <Box w={{ md: 'calc(100% - 240px)', base: '100%' }} mr="10px">
          {renderListTab()}
          {renderTabContent()}
        </Box>
        {labelDetail && (
          <Box w={{ md: '250px', base: '100%' }}>
            <Top10Contributors
              labelName={labelDetail?.name}
              labelId={labelDetail?._id}
            />
            <Box mt="15px">
              <Top10Genres
                labelName={labelDetail?.name}
                labelId={labelDetail?._id}
              />
            </Box>
            <Box mt="15px">
              <Top10Tags
                labelName={labelDetail?.name}
                labelId={labelDetail?._id}
              />
            </Box>
          </Box>
        )}
      </Flex>
    </>
  );
}
