import { useCommunitySlice } from 'app/components/Community/slice';
import { selectSliceCommunity } from 'app/components/Community/slice/selectors';
import { DEFAULT_PAGE_SIZE } from 'app/constants';
import { SORT_TYPE } from 'app/constants/enum';
import { UsernameFilter } from 'app/models';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export const useCommunity = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { actions } = useCommunitySlice();
  const timeoutRef = useRef<any>(null);

  const {
    users = [],
    isLoading,
    pagination,
    isLoadingFollow,
    isLoadingMore,
    currentPage,
    totalPage,
    contributors,
  } = useSelector(selectSliceCommunity);

  const DEFAULT_FILTERS = useMemo(() => {
    return {
      sort: SORT_TYPE.LATEST_UPLOADED_DESC,
      search: '',
      page: 1,
      pageSize:
        pathname.includes('/contributors') || pathname.includes('/charts')
          ? DEFAULT_PAGE_SIZE
          : 10,
      labelId: 'all',
    };
  }, [pathname]);
  const [filter, setFilter] = useState<UsernameFilter>(DEFAULT_FILTERS);
  const [searchValue, setSearchValue] = useState<string>('');

  const onGetCommunity = useCallback(
    (payload?: any) => {
      dispatch(
        actions.getCommunityRequest({
          filter: {
            ...filter,
            search: payload?.search || filter?.search,
            sort: payload?.sort || filter?.sort,
            labelId: payload?.labelId || filter?.labelId,
          },
        }),
      );
    },
    [actions, dispatch, filter],
  );

  const onGetContributors = useCallback(() => {
    dispatch(actions.getContributorsRequest());
  }, [actions, dispatch]);

  const onToggleBlockUser = useCallback(
    user => {
      dispatch(actions.toggleBlockUserRequest({ _id: user._id }));
    },
    [dispatch, actions],
  );

  const handleChange = useCallback(
    e => {
      setSearchValue(e.target.value);

      if (timeoutRef) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        setFilter(current => ({
          ...current,
          search: e.target.value,
          page: 1,
        }));
      }, 500);
    },
    [setFilter],
  );

  const handleChangeFilter = useCallback(
    (event, key) => {
      setFilter(current => ({
        ...current,
        [key]: event,
        page: 1,
      }));
    },
    [setFilter],
  );

  const fetchMoreData = useCallback(() => {
    setFilter(current => {
      return {
        ...current,
        page: currentPage + 1,
      };
    });
  }, [currentPage]);

  const handleObserverLoadMoreUsers = useCallback(
    entities => {
      const target = entities[0];
      if (
        target.isIntersecting &&
        totalPage > currentPage &&
        currentPage !== 0
      ) {
        fetchMoreData();
      }
    },
    [currentPage, fetchMoreData, totalPage],
  );

  return {
    onGetCommunity,
    handleChange,
    handleChangeFilter,
    onToggleBlockUser,
    users,
    isLoading,
    pagination,
    isLoadingFollow,
    setFilter,
    handleObserverLoadMoreUsers,
    setSearchValue,
    isLoadingMore,
    currentPage,
    onGetContributors,
    contributors,
    searchValue,
    filter,
    totalPage,
  };
};
