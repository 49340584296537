import axiosService from 'app/services/axios.service';

const WISHLIST_BASE_URL = '/v1/wishlists';

export const getMyWishlist = async () => {
  const response = await axiosService.get(`${WISHLIST_BASE_URL}/me`);
  return response?.data?.data;
};

export const addTrackToMyWishlist = async trackId => {
  const response = await axiosService.post(`${WISHLIST_BASE_URL}/add-tracks`, {
    id: trackId,
  });
  return response?.data?.data;
};

export const addReleaseToMyWishlist = async releaseId => {
  const response = await axiosService.post(
    `${WISHLIST_BASE_URL}/add-releases`,
    {
      id: releaseId,
    },
  );
  return response?.data?.data;
};

export const removeTrackToMyWishlist = async trackIds => {
  const response = await axiosService.post(
    `${WISHLIST_BASE_URL}/remove-tracks`,
    { trackIds },
  );
  return response?.data?.data;
};

export const removeReleaseToMyWishlist = async releaseIds => {
  const response = await axiosService.post(
    `${WISHLIST_BASE_URL}/remove-releases`,
    { releaseIds },
  );
  return response?.data?.data;
};
