import { Box, Flex, Image, Text, Tooltip } from '@chakra-ui/react';
import PlaceholderBgDefault from 'app/assets/placeholders/avatar.jpeg';
import { User } from 'app/models';
import { Link } from 'react-router-dom';
import styles from './community.module.scss';
import { formatDate } from 'app/utils/date';

interface CommunityItemProps {
  user: User;
}

const CommunityItem = ({ user }: CommunityItemProps) => {
  const {
    username,
    avatar,
    slug,
    totalTracks,
    totalReleases,
    totalLabels,
    lastUploadTrack,
    canUploadToLabels,
  } = user;

  return (
    <Box className={styles.contributorItem}>
      <Link to={`/contributors/${slug}`}>
        <Image
          src={avatar || PlaceholderBgDefault}
          fallbacksrc={PlaceholderBgDefault}
          height="276px"
          width="100%"
          objectFit="cover"
        />
        <Box className={styles.contributorName}>{username}</Box>
      </Link>
      {/* <Flex className={styles.linkTo}>
        <Text className={styles.linkItem}>Top 100</Text>
        <Text className={styles.linkItem}>new releases</Text>
      </Flex> */}
      <Box w="100%" padding="10px" fontSize="12px">
        {lastUploadTrack && (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            lineHeight="22px"
          >
            <Text>Last Upload:</Text>
            <Text fontWeight="600">{formatDate(lastUploadTrack)}</Text>
          </Flex>
        )}
        <Flex
          justifyContent="space-between"
          alignItems="center"
          lineHeight="22px"
        >
          <Text>Total Tracks:</Text>
          <Text fontWeight="600">{totalTracks}</Text>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          lineHeight="22px"
        >
          <Text>Total Multipacks:</Text>
          <Text fontWeight="600">{totalReleases}</Text>
        </Flex>
        <Flex
          justifyContent="space-between"
          alignItems="center"
          lineHeight="22px"
        >
          <Text>Labels:</Text>
          <Tooltip
            label={
              <Box>
                {canUploadToLabels.map((item, idx) => (
                  <Text key={idx}>{item.name}</Text>
                ))}
              </Box>
            }
          >
            <Text fontWeight="600">{totalLabels}</Text>
          </Tooltip>
        </Flex>
      </Box>
    </Box>
  );
};

export default CommunityItem;
